
import React, { useState } from 'react';
import photo from '../images/question.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
function Faq() {

    const { t } = useTranslation()

    const [openAccordion, setOpenAccordion] = useState(null);

    const toggleAccordion = (index) => {
        setOpenAccordion(openAccordion === index ? null : index);
    };
const faqs = [
    {
        question: t("What makes your link-building service unique?"),
        answer: t("Our link-building strategy focuses on high-authority backlinks that elevate your website’s authority and drive organic traffic."),
    },
    {
        question: t("How quickly can I see results from SEO services?"),
        answer: t("SEO results typically take 3-6 months to show significant progress, but our fast and effective approach ensures you see steady growth along the way."),
    },
    {
        question: t("Do you offer custom SEO strategies?"),
        answer: t("Yes, we tailor each SEO strategy to the unique needs of your business, ensuring personalized and sustainable growth."),
    },
    {
        question: t("What’s included in your content creation service?"),
        answer: t("Our content creation services include blog posts, website copy, and articles that not only drive traffic but also engage your target audience."),
    },
];



    return (
        <div className='w-full flex flex-col gap-14 justify-center items-center  py-14 md:py-[7rem] bg-gray-100 md:px-56 px-3'>
            <div className='w-full flex justify-center text-center gap-5 md:px-56 items-center flex-col'>
                <img
                    src={photo}
                    alt="Background Photo"
                    className="rounded-xl w-[15rem]"
                />

                <h1 className='md:text-[75px] text-[30px] font-[900]  leading-[50px] md:leading-[80px] text-primary capitalize'>
                    {t("Frequently Asked Questions")}
                </h1>
                <p className='md:text-[24px] text-[14px] font-[400] md:leading-[40px] leading-[30px] text-primary'>
                    {t("Find answers to the most common questions about our services, processes, and how we can help elevate your online presence. We've got you covered !")}
                </p>



                <div className="md:mt-14 mt-5" >
                    {faqs.map((faq, index) => (
                        <div
                            key={index}
                            className={` border border-solid border-gray-300 p-4  rounded-xl mb-6 lg:p-4 ${openAccordion === index ? 'bg-gray-50 border-primary' : ''
                                }`}
                        >
                            <button
                                className=" inline-flex items-center justify-between md:text-[19px] text-[13px] font-[600] leading-[20px] text-primary w-full transition duration-500 "
                                onClick={() => toggleAccordion(index)}
                            >
                                <h5 className='text-start'>{faq.question}</h5>
                                <svg
                                    className={`w-6 h-6 text-gray-900 transition duration-500 ${openAccordion === index ? 'hidden' : 'block'
                                        } group-hover:text-indigo-600 origin-center`}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M6 12H18M12 18V6" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <svg
                                    className={`w-6 h-6 text-gray-900 transition duration-500 ${openAccordion === index ? 'block' : 'hidden'
                                        } group-hover:text-indigo-600`}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M6 12H18" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                            <div
                                className=" w-full overflow-hidden pr-4 transition-max-height duration-500"
                                style={{
                                    maxHeight: openAccordion === index ? '500px' : '0px',
                                }}
                            >
                                <p className="md:text-[18px] text-[15px] font-[400] text-start leading-[26px] text-primary mt-2">
                                    {faq.answer}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className='flex justify-center items-center gap-3 mt-4 w-full'>
               <Link to='/Contact' className='flex justify-center items-center gap-2 capitalize border-gray-800 border md:px-5 px-2.5 py-2.5 rounded-full bg-white/10 text-gray-800  font-[400]   md:text-[16px] text-[14px] w-fit '>
                    <span>{t("Let’s Get Started")}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                </Link>
                    <button className='flex justify-center items-center gap-2 capitalize border-primary border md:px-5 px-2.5 py-2.5 rounded-full bg-secondary text-primary font-[500]   md:text-[16px] text-[14px] w-fit'>
                        <span>{t("Dashboard")}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right">
                            <path d="M7 7h10v10" />
                            <path d="M7 17 17 7" />
                        </svg>
                    </button>
                </div>

            </div>






        </div>
    )
}

export default Faq