import React from 'react';
import portfolio1 from '../images/portfolio1.jpg';
import portfolio2 from '../images/portfolio2.jpg';
import portfolio3 from '../images/portfolio3.jpg';
import portfolio4 from '../images/portfolio4.jpg';
import portfolio5 from '../images/portfolio5.jpg';
import portfolio6 from '../images/portfolio6.jpg';
import { useTranslation } from 'react-i18next';

function Testimonials() {
        const { t } = useTranslation()

   const data = [
    {
        imageLink: portfolio1,
        title: t("E-commerce Revamp"),
        subtitle: t('Witness a stunning 150% increase in organic traffic after our strategic link-building campaign, transforming their sales potential.')
    },
    {
        imageLink: portfolio2,
        title: t('Local Business Surge'),
        subtitle: t('Our targeted SEO efforts catapulted this local business into the spotlight, achieving a remarkable 200% traffic growth in just three months.')
    },
    {
        imageLink: portfolio3,
        title: t('Blogging Breakthrough'),
        subtitle: t('See how our backlink strategy helped this blog climb the ranks, resulting in a 300% boost in visitor engagement and retention.')
    },
    {
        imageLink: portfolio4,
        title: t('Tech Startup Triumph'),
        subtitle: t('From obscurity to prominence, our SEO tactics delivered a 250% increase in traffic, positioning this startup as a leader in its niche.')
    },
    {
        imageLink: portfolio5,
        title: t('Health & Wellness Hub'),
        subtitle: t('Our comprehensive SEO overhaul enhanced visibility, leading to a staggering 180% rise in website visits and customer inquiries.')
    },
    {
        imageLink: portfolio6,
        title: t('Real Estate Revolution'),
        subtitle: t('Transforming online presence, our link-building services drove a phenomenal 220% increase in organic search traffic for this agency.')
    },
];


    return (
        <div className='w-full flex flex-col gap-10 justify-center items-center py-12 md:py-[7rem] md:px-32 px-3'>
            <div className='w-full flex justify-center text-center gap-5 md:px-56 items-center flex-col'>
                <div className='flex justify-center items-center gap-2 capitalize border-primary border md:px-5 px-3 py-2.5 rounded-full bg-white/10 text-primary font-[400] md:text-[12px] text-[10px] w-fit'>
                    <span>{t("Boost Your Influence with Smarter Links!")}</span>🔥
                </div>
                <h1 className='md:text-[55px] text-[30px] font-[900] md:leading-[70px] leading-[55px] text-primary capitalize'>
                    {t("Websites That Thrive")}
                </h1>
                <p className='md:text-[16px] text-[14px] font-[400] md:leading-[40px] leading-[30px] text-primary'>
                    {t("Discover how our tailored strategies have transformed businesses, boosting their online presence and driving success through authentic link-building and effective SEO solutions. Hear from our satisfied clients about their journeys to digital excellence.")}
                </p>
            </div>
            <div className="grid grid-cols-1 gap-8  xl:mt-12 w-full md:px-14 xl:gap-12 lg:grid-cols-3">
                {data.map((item, index) => (
                    <div className="flex items-end overflow-hidden border shadow rounded-xl border-gray-300 h-96 relative group">
                        <img
                            src={item.imageLink}
                            alt={item.title}
                            className="rounded-lg object-cover h-full"
                        />
                        <div className="w-full absolute transition-opacity duration-300 ease-in-out opacity-100 group-hover:opacity-0 md:px-8 px-3 py-4 overflow-hidden rounded-b-lg backdrop-blur-sm bg-white/10">
                            <h2 className="mt-4 md:text-[24px] text-[22px] font-[700] text-primary capitalize ">{item.title}</h2>
                            <p className="mt-2 md:text-[16px] text-[14px] font-[500] text-primary capitalize ">{item.subtitle}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Testimonials;
