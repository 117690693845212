import React, { useEffect } from 'react'

import Hero from "../components/Hero";
import WhyUs from "../components/WhyUs";
import Services from "../components/Services";
import Faq from "../components/Faq";
import Testimonials from "../components/Testimonials";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

export default function Home() {

    const { t } = useTranslation()

    const { pathname } = useLocation();

    useEffect(() => {
 window.scrollTo({
      top: 0,
      behavior: 'smooth' // This makes the scroll smooth
    });    }, [pathname]);


    return (
        <div className="h-full w-full">
            <Helmet>
                <title>{t("Meta Title Home")}</title>
                <meta name="description" content={t("Meta Description Home")} />
            </Helmet>
            <Hero />
            <WhyUs />
            <Services />
            <Testimonials />
            <Faq />

        </div>
    );
}
