import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, BrowserRouter } from 'react-router-dom';

import Navbar from './layout/Navbar';
import Footer from './layout/Footer';

import Home from './pages/Home';
import Services from './pages/Services';
import Blog from './pages/Blog';
import Post from './pages/Post';
import Contact from './pages/Contact';
import Pricing from './pages/Pricing';
import i18n from './i18next';
import { I18nextProvider } from 'react-i18next';

function App() {

  useEffect(() => {
    const setInitialValues = async () => {
      if (process.env.REACT_APP_DEFAULT_LANGUAGE !== "ar") {
        localStorage.removeItem('i18nextLng');

      }
      const currentLanguage = localStorage.getItem('i18nextLng') || process.env.REACT_APP_DEFAULT_LANGUAGE;

      if (currentLanguage === 'ar') {
        document.body.style.direction = 'rtl';
        document.body.style.fontFamily = '"Tajawal", sans-serif';
      } else {
        document.body.style.direction = 'ltr';
        document.body.style.fontFamily = '"Montserrat", sans-serif';
      }
    };

    setInitialValues();
  }, []);


  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>

        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Post/:slug" element={<Post />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Pricing" element={<Pricing />} />

        </Routes>
        <Footer />
      </I18nextProvider >

    </BrowserRouter>
  );
}

export default App;
