import React, { useEffect } from 'react'
import Hero from '../components/Pricing/Hero';
import Faq from '../components/Faq'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

function Pricing() {
    const { t } = useTranslation()


    const { pathname } = useLocation();

    useEffect(() => {
 window.scrollTo({
      top: 0,
      behavior: 'smooth' // This makes the scroll smooth
    });    }, [pathname]);


    return (
        <div className="h-full w-full">
           <Helmet>
                <title>{t("Meta Title Pricing")}</title>
                <meta name="description" content={t("Meta Description Pricing")} />
            </Helmet>
            <Hero />

            <div className='flex gap-6 flex-col justify-center items-center mx-auto py-24 md:w-[50%] w-full'>

                <h1 className='md:text-[50px] text-center text-[35px]  font-[800] leading-[60px] md:leading-[80px] text-primary'>
                    {t("Pricing Plans Coming Soon")}
                </h1>
                <p className='md:text-[20px] text-center text-[18px] font-[500] leading-[30px] md:leading-[35px] text-primary'>
                    {t("We're constantly working to provide more flexible options. Currently, no plans are available. Stay tuned for our upcoming plans tailored for growing businesses.")}
                </p>
            </div>


            <Faq />

        </div>
    )
}

export default Pricing