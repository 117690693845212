import React from 'react'
import photo from '../../images/whyus.avif';
import { useTranslation } from 'react-i18next';

function Form() {
            const { t } = useTranslation()

    return (
        <div className='flex justify-center md:flex-row flex-col px-5 mx-auto items-center py-12 md:py-[8rem] md:w-[62%] gap-5 md:gap-24 w-full h-full'>

            <div className='md:w-2/3 relative  w-full'>

                <img
                    src={photo}
                    alt="Background Photo"
                    width={1920} // Set a width
                    height={700} // Set a height
                    className="rounded-xl "
                />

            </div>

            <div className='flex justify-end flex-col items-start h-full  gap-4 h-full md:w-1/2 w-full'>

                <div className='flex md:text-[18px] text-[17px] gap-3 font-[400] leading-[30px] justify-end flex-col items-start h-full  W-full'>
                    <h1 className='md:text-[45px] text-[25px] font-[900] md:leading-[50px]  leading-[40px]  text-primary capitalize'>
                        {t("Send Us Message")}
                    </h1>
                    <p className='text-primary text-[15px] font-[400] capitalize'>
                        {t("fill up the form and our team will get back to you within 24 hours.")}
                    </p>

                </div>

                <form className='w-full'>

                    <div className='flex w-full justify-center items-start flex-col gap-4'>

                        <div className='flex justify-center text-primary  w-full items-start flex-col gap-2'>
                            <label className='text-[16px] font-[600]'>{t("Your Name *")}</label>
                            <input type='text' className='w-full h-14 rounded-md outline-none flex px-4 bg-gray-100 text-primary placeholder-gray-500 ' placeholder={t("Enter your name ...")} />

                        </div>

                        <div className='flex justify-center text-primary  w-full items-start flex-col gap-2'>
                            <label className='text-[16px] font-[600]'>{t("Email *")}</label>
                            <input type='email' className='w-full h-14 rounded-md outline-none flex px-4 bg-gray-100 text-primary placeholder-gray-500 ' placeholder={t("Enter your email  ...")} />

                        </div>

                        <div className='flex justify-center text-primary  w-full items-start flex-col gap-2'>
                            <label className='text-[16px] font-[600]'>{t("Message *")}</label>
                            <textarea rows={4} className='w-full py-4 rounded-md outline-none flex px-4 bg-gray-100 text-primary placeholder-gray-500 ' placeholder={t("Enter your message ...")}></textarea>
                        </div>



                        <button className='flex justify-center items-center gap-2 capitalize border-primary border px-5 py-2.5 rounded-full bg-primary text-secondary font-[500] mt-5 text-[16px]  w-full'>
                            <span>{t("Send Message")}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right">
                                <path d="M7 7h10v10" />
                                <path d="M7 17 17 7" />
                            </svg>
                        </button>

                    </div>

                </form>


            </div>



        </div>)
}

export default Form