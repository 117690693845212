
import React, { useEffect, useState } from 'react';
import Faq from '../components/Faq';
import Hero from '../components/Post/Hero';
import axios from 'axios';
import Loading from '../components/Loading';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Post = () => {
    const { slug } = useParams();
    const [postData, setPostData] = useState(null);
    const [loading, setLoading] = useState(true);
    const savedLanguage = localStorage.getItem('i18nextLng') || process.env.REACT_APP_DEFAULT_LANGUAGE;
    const language = process.env.REACT_APP_LANGUAGE_SWITCH;




    const getUrl = () => {
        if (savedLanguage == 'ar') {
            return `backlinksarab`
        } else if (savedLanguage == 'fr') {
            return 'seofocus'
        } else if (savedLanguage == 'en' && language === 'arabic') {
            return `backlinksarab`

        } else if (savedLanguage == 'en' && language !== 'arabic') {
            return 'SmartTrafficBoost'
        }
    }


    const { pathname } = useLocation();

    useEffect(() => {
 window.scrollTo({
      top: 0,
      behavior: 'smooth' // This makes the scroll smooth
    });    }, [pathname]);


    useEffect(() => {
        const fetchPostData = async () => {
            if (slug) {
                try {
                    const response = await axios.get(`https://api.dashforme.xyz/public/api/blogs/FindBlog/${getUrl()}/${slug}`);
                    const responseData = response.data;

                    if (responseData) {
                        setPostData(responseData.data);
                    }
                } catch (error) {
                    console.error('Error fetching post data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchPostData();
    }, [slug]);

    return (
        <div className="h-full w-full">
            <Helmet>
                <title>{postData?.meta_title}</title>
                <meta name="description" content={postData?.meta_description} />
            </Helmet>
            <Hero postData={postData} />
            {loading ? (
                <Loading />
            ) : (
                <div className='flex flex-col justify-center items-start md:px-56 px-3 gap-3 md:py-24 py-10'>


                    <div className='flex justify-center items-start mt-4 flex-col' dangerouslySetInnerHTML={{ __html: postData?.content }}>
                    </div>
                </div>
            )}

            <Faq />
        </div>
    );
};



export default Post;
