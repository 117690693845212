import React, { useState } from 'react'
import logo from '../images/logo.png';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logoArab from '../images/logoArab.png';
import logoFrench from '../images/logoFrench.png';
import logoEnglish from '../images/logoEnglish.png';
import LanguageDropDown from '../components/LanguageDropDown';
function Navbar() {
    const { t } = useTranslation()

    const [dropdown, setDropDown] = useState(false)
    const location = useLocation();

    const savedLanguage = localStorage.getItem('i18nextLng') || process.env.REACT_APP_DEFAULT_LANGUAGE;
    const language = process.env.REACT_APP_LANGUAGE_SWITCH;



    const getImage = () => {
        if (savedLanguage == 'ar') {
            return logoArab
        } else if (savedLanguage == 'fr') {
            return logoFrench
        } else if (savedLanguage == 'en' && language === 'arabic') {
            return logoArab

        } else if (savedLanguage == 'en' && language !== 'arabic') {
            return logoEnglish
        }
    }


    return (
        <div className='flex  md:justify-center justify-between absolute top-0 z-50 w-screen items-center md:px-[4rem] px-6 md:h-[6.8rem] h-[4rem]'>
            <div className='md:flex hidden justify-start items-center w-1/3 '>
                <Link to='/'><img src={getImage()} alt='logo' objectFit="cover" className='w-56' /></Link>

                {/* <h1 className='text-secondary font-[900] text-[30px]'>Logo.</h1> */}
            </div>

            <div className="text-secondary md:hidden  w-full flex justify-between items-center">
                <button onClick={() => setDropDown(!dropdown)} className="focus:outline-none z-50">
                    {dropdown ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="size-6 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    )}
                </button>
                <Link to='/'><img src={getImage()} alt='logo' objectFit="cover" className='w-44' /></Link>

                {dropdown && (
                    <div
                        className={`absolute top-[4rem] right-0 flex-col flex w-screen justify-start items-center z-50 bg-white duration-300 ease-in-out transform ${dropdown ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'
                            }`}
                    >
                        <Link
                            onClick={() => setDropDown(!dropdown)}
                            to="/"
                            className="text-primary scroll-link cursor-pointer font-[700] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[14px]"
                        >
                            {t("Home")}
                        </Link>
                        <Link
                            onClick={() => setDropDown(!dropdown)}
                            to="/Services"
                            className="text-primary scroll-link cursor-pointer font-[700] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[14px]"
                        >
                            {t("Services")}
                        </Link>
                        <Link
                            onClick={() => setDropDown(!dropdown)}
                            to="/Contact"
                            className="text-primary scroll-link cursor-pointer font-[700] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[14px]"
                        >
                            {t("Contact")}
                        </Link>
                        <Link
                            onClick={() => setDropDown(!dropdown)}
                            to="/Pricing"
                            className="text-primary scroll-link cursor-pointer font-[700] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[14px]"
                        >
                            {t("Pricing")}
                        </Link>
                        <Link
                            onClick={() => setDropDown(!dropdown)}
                            to="/Blog"
                            className="text-primary scroll-link cursor-pointer font-[700] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[14px]"
                        >
                            {t("Blog's")}
                        </Link>
                    </div>
                )}

                  {
                    language === 'arabic' &&
                    <LanguageDropDown />

                }
            </div>


            <ul className='md:flex hidden justify-center gap-12 w-2/3  cursor-pointer items-center text-gray-300   font-[400] text-[16px] capitalize'>
                <li className={`hover:text-secondary  ${location.pathname == '/' ? 'border-b-[2px] text-secondary border-secondary' : ''}`}><Link to='/'>{t("Home")}</Link></li>
                <li className={`hover:text-secondary ${location.pathname == '/Pricing' ? 'border-b-[2px] text-secondary border-secondary' : ''}`}><Link to='/Pricing'>{t("Pricing")}</Link></li>
                <li className={`hover:text-secondary ${location.pathname == '/Services' ? 'border-b-[2px] text-secondary border-secondary' : ''}`}><Link to='/Services'>{t("Services")}</Link></li>
                <li className={`hover:text-secondary ${location.pathname == '/Contact' ? 'border-b-[2px] text-secondary border-secondary' : ''}`}><Link to='/Contact'>{t("Contact Us")}</Link></li>
                <li className={`hover:text-secondary ${location.pathname == '/Blog' ? 'border-b-[2px] text-secondary border-secondary' : ''}`}><Link to='/Blog'>{t("Blog's")}</Link></li>
            </ul>

            <div className='md:flex hidden justify-end items-center gap-3 w-1/3'>
                {
                    language === 'arabic' &&
                    <LanguageDropDown />

                }

                {language !== 'arabic' &&

                    <Link to='/Contact' className='flex justify-center items-center gap-2 capitalize border-gray-300 border px-5 py-2.5 rounded-full bg-white/10 text-gray-300  font-[400] text-[16px] w-fit '>
                        <span>{t("Let’s Get Started")}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                    </Link>
                }

                <button className='flex justify-center items-center gap-2 capitalize border-secondary border px-5 py-2.5 rounded-full bg-secondary/10 text-secondary  font-[400] text-[16px] w-fit '>
                    <span>{t("Dashboard")}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                </button>


            </div>


        </div>

    )
}

export default Navbar