import React, { useEffect } from 'react'
import Faq from '../components/Faq';
import Hero from '../components/Services/Hero';
import photo1 from '../images/Backlinks.webp';
import photo2 from '../images/content.png';
import photo3 from '../images/Seo.png';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useTranslation } from 'react-i18next';

function ServiceSection({ image, title, description, direction, reasons }) {


    const { pathname } = useLocation();

    useEffect(() => {
 window.scrollTo({
      top: 0,
      behavior: 'smooth' // This makes the scroll smooth
    });    }, [pathname]);

    const { t } = useTranslation()


    return (
        <div className="flex justify-center md:flex-row flex-col px-5 mx-auto items-center py-12 md:w-[72%] gap-10 w-full">
            <Helmet>
                <title>{t("Meta Title Services")}</title>
                <meta name="description" content={t("Meta Description Services")} />
            </Helmet>
            <div className={`md:w-1/2 relative ${direction ? 'md:hidden flex' : ''} w-full`}>
                <img src={image} alt={title} className="rounded-xl" />
            </div>
            <div className="flex justify-end flex-col items-start h-full gap-4 md:w-1/2 w-full">
                <h1 className="md:text-[35px] text-[22px] font-[900] leading-[40px] md:leading-[50px] text-primary capitalize">{title}</h1>
                <div className="flex md:text-[17px] text-[14px] font-[400] leading-[30px] flex-col items-start gap-5">
                    <p className="text-black capitalize">{description}</p>
                    <div className="flex justify-center items-start gap-2 flex-col w-full">
                        {/* <h2 className="md:text-[25px] text-[20px] font-[900] leading-[30px] text-primary capitalize">{t("Why Choose Us?")}</h2> */}
                        <ul className="flex flex-col gap-3 md:text-[16px] text-[13px] font-[400] leading-[30px] w-full">
                            {reasons.map((reason, index) => (
                                <li key={index}>
                                    <p className="text-black capitalize">
                                        <b>{reason.title}:</b> {reason.description}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <ActionButton />
                </div>

            </div>
            {
                direction &&
                <div className="md:w-1/2 md:flex hidden relative w-full">
                    <img src={image} alt={title} className="rounded-xl" />
                </div>
            }

        </div >
    );
}

// Reusable Button Component
function ActionButton() {
    const { t } = useTranslation()

    return (
        <div className="flex justify-start mt-3 items-center gap-3 w-full">

            <Link to='/Contact' className='flex justify-center items-center gap-2 capitalize border-gray-800 border md:px-5 px-2.5 py-2.5 rounded-full bg-white/10 text-gray-800  font-[400]   md:text-[16px] text-[14px] w-fit '>
                <span>{t("Let’s Get Started")}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
            </Link>
            <button className='flex justify-center items-center gap-2 capitalize border-primary border md:px-5 px-2.5 py-2.5 rounded-full bg-secondary text-primary font-[500]   md:text-[16px] text-[14px] w-fit'>
                <span>{t("Dashboard")}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right">
                    <path d="M7 7h10v10" />
                    <path d="M7 17 17 7" />
                </svg>
            </button>
        </div>
    );
}

export default function Services() {
    const { t } = useTranslation()


    const linkBuildingReasons = [
        { title: t('High-Quality Backlinks'), description: t('We focus on obtaining links from authoritative websites within your niche.') },
        { title: t('Improved Domain Authority'), description: t("Increase your website's credibility and ranking potential.") },
        { title: t('Organic Traffic Boost'), description: t('Experience sustained organic traffic growth that benefits your SEO in the long run.') },
        { title: t('Custom Strategy'), description: t('Each business is unique, and so is our approach. We tailor every campaign to your specific needs.') }
    ];

    const seoReasons = [
        { title: t('On-Page SEO'), description: t('We optimize your content, metadata, headers, and images to ensure your website meets search engine criteria.') },
        { title: t('Off-Page SEO'), description: t('Through strategic link-building and brand mentions, we enhance your online reputation.') },
        { title: t('Keyword Research'), description: t('We identify and target the most effective keywords for your industry to drive relevant traffic.') },
        { title: t('Technical SEO'), description: t('We audit your site for speed, mobile optimization, and crawlability to ensure a smooth experience for users and search engines alike.') }
    ];

    const contentCreationReasons = [
        { title: t('Blog Writing'), description: t('Regular, SEO-optimized blog posts to establish thought leadership in your industry.') },
        { title: t('Product Descriptions'), description: t('Clear and persuasive product descriptions that compel users to take action.') },
        { title: t('Web Copywriting'), description: t('Engaging and concise web copy designed to increase user engagement and conversions.') },
        { title: t('Social Media Content'), description: t('Drive traffic and interactions with custom-tailored content for platforms like Instagram, Twitter, and Facebook.') }
    ];


    return (
        <div className="h-full w-full">
            <Hero />
            <ServiceSection
                image={photo1}
                title={t("Link Building: Strengthen Your Digital Authority")}
                description={t("Unlock the power of high-authority backlinks to boost your website’s credibility. With our Link Building service, we create a personalized strategy to help your domain rank higher on search engines while increasing organic traffic.")}
                reasons={linkBuildingReasons}
            />
            <ServiceSection
                image={photo3}
                direction={true}
                title={t("SEO Solutions: Achieve Top Search Engine Rankings")}
                description={t("Our SEO Solutions encompass everything from on-page optimization to off-page strategies that will give your business the visibility it deserves.")}
                reasons={seoReasons}
            />
            <ServiceSection
                image={photo2}
                title={t("Content Creation: Engage and Convert Your Audience")}
                description={t("Crafting high-quality content that resonates with your audience is key to boosting traffic and engagement. Our Content Creation service focuses on delivering content that not only informs but also converts.")}
                reasons={contentCreationReasons}
            />
            <Faq />
        </div>
    );
}
