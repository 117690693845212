import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Services() {
    const { t } = useTranslation()

    return (
        <div className='w-full flex flex-col gap-14 justify-center items-center py-12 h-full  md:py-[7rem] bg-primary md:px-32 px-3'>
            <div className='w-full flex justify-center text-center md:gap-5 gap-2 md:px-56 items-center flex-col'>
                <div className='flex justify-center items-center gap-2 capitalize border-gray-300 border md:px-5 px-3 py-2.5 rounded-full bg-white/10 text-gray-300 font-[400] md:text-[12px] text-[10px] w-fit'>
                    <span>{t("Boost Your Influence with Smarter Links!")}</span>🔥
                </div>
                <h1 className='md:text-[55px] text-[30px] font-[900] md:leading-[70px] leading-[55px] text-white capitalize'>
                    {t("Our Core Services: Elevating Your")} <span className='text-secondary'>{t("Online Presence")}</span>
                </h1>
                <p className='md:text-[22px]   md:text-[16px] text-[14px] font-[300] leading-[40px] text-gray-100'>
                    {t("Strategic Solutions for Lasting Impact")}
                </p>
            </div>



            <div className='flex md:flex-row flex-col h-full justify-center items-start gap-6'>

                <div className=' md:w-1/3 w-full h-full rounded-xl bg-white/10 px-7 py-5 flex justify-center flex-col items-start gap-1'>

                    <div className='rounded-xl p-3 text-primary bg-accent flex justify-center items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" /><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" /></svg>
                    </div>

                    <h1 className='md:text-[28px] text-[20px] font-[800]  leading-[60px] text-white capitalize'>
                        {t("Link Building")}
                    </h1>
                    <p className='  md:text-[16px] text-[14px] font-[400] leading-[30px] text-gray-100'>
                        {t("Unlock the power of high-authority backlinks to boost your domain authority, improve search rankings, and increase organic traffic to your site.")}

                    </p>

                </div>


                <div className=' md:w-1/3 w-full h-full rounded-xl bg-white/10 px-7 py-5 flex justify-center flex-col items-start gap-1'>
                    <div className='rounded-xl p-3 text-primary bg-accent flex justify-center items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-globe"><circle cx={12} cy={12} r={10} /><path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20" /><path d="M2 12h20" /></svg>
                    </div>
                    <h1 className='md:text-[28px] text-[20px] font-[800]  leading-[60px] text-white capitalize'>
                        {t("SEO Solutions")}
                    </h1>
                    <p className='  md:text-[16px] text-[14px] font-[400] leading-[30px] text-gray-100'>
                        {t("Implement comprehensive on-page and off-page optimization strategies that help your business rank higher on Google, driving more visibility and engagement.")}

                    </p>

                </div>

                <div className=' md:w-1/3 w-full h-full rounded-xl bg-white/10 px-7 py-5 flex justify-center flex-col items-start gap-1'>
                    <div className='rounded-xl p-3 text-primary bg-accent flex justify-center items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-monitor-speaker"><path d="M5.5 20H8" /><path d="M17 9h.01" /><rect width={10} height={16} x={12} y={4} rx={2} /><path d="M8 6H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h4" /><circle cx={17} cy={15} r={1} /></svg>
                    </div>

                    <h1 className='md:text-[28px] text-[20px] font-[800]  leading-[60px] text-white capitalize'>
                        {t("Content Creation")}
                    </h1>
                    <p className='  md:text-[16px] text-[14px] font-[400] leading-[30px] text-gray-100'>
                        {t("Craft expert-level content designed to both drive traffic and engage your audience, ensuring your message resonates and converts.")}

                    </p>

                </div>

            </div>


            <div className='flex justify-center items-center gap-3 w-full'>
                <Link to='/Contact' className='flex justify-center items-center gap-2 capitalize border-gray-300 border md:px-5 px-2.5 py-2.5 rounded-full bg-white/10 text-gray-300  font-[400]   md:text-[16px] text-[14px] w-fit '>
                    <span>{t("Let’s Get Started")}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                </Link>
                <button className='flex justify-center items-center gap-2 capitalize border-primary border md:px-5 px-2.5 py-2.5 rounded-full bg-secondary text-primary font-[500] te  md:text-[16px] text-[14px] w-fit'>
                    <span>{t("Dashboard")}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right">
                        <path d="M7 7h10v10" />
                        <path d="M7 17 17 7" />
                    </svg>
                </button>
            </div>

        </div>
    )
}

export default Services