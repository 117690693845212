import React from 'react';
import photo from '../../images/photo.jpg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Hero() {
                const { t } = useTranslation()

    return (
        <div className='w-full h-fit  relative flex justify-center items-center' >
            <div className="absolute inset-0 bg-primary z-10 opacity-60"></div>
            <div
                className="absolute inset-0 bg-cover rotate-180 bg-center"
                style={{ backgroundImage: `url(${photo})` }}
            ></div>



            {/* Content Section */}
            <div className='flex z-20 flex-col py-20 justify-center md:w-[50%] text-center w-full mt-12 md:mt-24 mx-auto px-3 items-center gap-5 '>

                <h1 className='md:text-[48px] text-[30px]  font-[800] leading-[40px] capitalize md:leading-[80px] text-gray-100'>
                    <span className='text-secondary'>{t("Our Services")}  </span>{t("Our Services")}
                </h1>
                <p className='md:text-[18px] text-[14px] font-[300] leading-[25px] md:leading-[40px] text-gray-100'>
                    {t("At Smart Traffic Boost, we offer tailored services to enhance your online presence. Whether you're aiming for top search rankings, quality backlinks, or engaging content, we have the solution. Discover our core services below.")}
                </p>
                <div className='flex justify-center items-center gap-3 w-full'>

                    <Link to='/Contact' className='flex justify-center items-center gap-2 capitalize border-gray-300 border md:px-5 px-2.5 py-2.5 rounded-full bg-white/10 text-gray-300  font-[400]   md:text-[16px] text-[14px] w-fit '>
                        <span>{t("Let’s Get Started")}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                    </Link>
                    <button className='flex justify-center items-center gap-2 capitalize border-primary border md:px-5 px-2.5 py-2.5 rounded-full bg-secondary text-primary font-[500]   md:text-[16px] text-[14px] w-fit'>
                        <span>{t("Dashboard")}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right">
                            <path d="M7 7h10v10" />
                            <path d="M7 17 17 7" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Hero;
