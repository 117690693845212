import React from 'react'
import photo from '../images/whyus.avif';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function WhyUs() {
            const { t } = useTranslation()

    return (
        <div className='flex justify-center md:flex-row flex-col px-3 mx-auto items-center py-12 md:py-[8rem] md:w-[72%] gap-10 w-full'>

            <div className='md:w-1/2 relative  w-full'>

                <img
                    src={photo}

                    alt="Background Photo"
                    className="rounded-xl"
                />
            </div>

            <div className='flex justify-end flex-col items-start h-full md:gap-14 gap-8 md:w-1/2 w-full'>
                <h1 className='md:text-[45px] text-[30px] font-[900] md:leading-[50px]  leading-[40px]  text-primary capitalize'>
                    {t("Why smart traffic boost ?")}
                </h1>
                <div className='flex md:text-[18px] text-[14px] font-[400] leading-[30px] justify-end flex-col items-start h-full gap-10 W-full'>
                    <p className=' text-black capitalize'>
                        {t("At")} <b className='text-primary'>{t("smart trafic boost")}</b>, {t("we believe in quality over quantity. Every backlink we create is designed to be powerful and authentic, ensuring it adds real value to your website’s ranking. With years of experience in SEO, our strategies are crafted to deliver sustainable, long-term growth that drives success. ")}                   </p>
                    <p className=' text-black capitalize'>
                        {t("We understand the need for quick results without compromising on quality. Our approach is both fast and effective, helping you see progress swiftly. Throughout the campaign, we maintain a transparent process, giving you full visibility into each step so you're always informed.")}                    </p>

                </div>

                <div className='flex justify-start items-center gap-3 w-full'>
                    <Link to='/Contact' className='flex justify-center items-center gap-2 capitalize border-gray-800 border md:px-5 px-2.5 py-2.5 rounded-full bg-white/10 text-gray-800  font-[400]   md:text-[16px] text-[14px] w-fit '>
                        <span>{t("Let’s Get Started")}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                    </Link>
                    <button className='flex justify-center items-center gap-2 capitalize border-primary border md:px-5 px-2.5 py-2.5 rounded-full bg-secondary text-primary font-[500]   md:text-[16px] text-[14px] w-fit'>
                        <span>{t("Dashboard")}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right">
                            <path d="M7 7h10v10" />
                            <path d="M7 17 17 7" />
                        </svg>
                    </button>
                </div>
            </div>



        </div>
    )
}

export default WhyUs